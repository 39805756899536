import React from "react"
import Logo from "./../images/logo.svg"

const Footer = () => {
  return (
    <footer className="page__footer" id="page__footer">
      <div className="container">
        <div className="content">
          <div className="grid main-grid">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.6699441912087!2d14.502474316151156!3d50.073742579425186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b931c704fced3%3A0xa998f04719466ae1!2zViBPbMWhaW7DoWNoIDIzMDAsIDEwMCAwMCBTdHJhxaFuaWNl!5e0!3m2!1sen!2scz!4v1594545066767!5m2!1sen!2scz"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="Lokace provozovny"
              ></iframe>
            </div>

            <div className="text-col" id="page__contact">
              <h3 className="logo">
                <img src={Logo} alt="Logo BEKAP s.r.o." />
              </h3>
              <div className="text-col__content grid">
                <div className="text-col__item text-col__item--ceo">
                  <h4>Jednatelka</h4>
                  <p>Ing. Bémová Věra</p>
                </div>
                <div className="text-col__item text-col__item--office">
                  <h4>Provozovna</h4>
                  <p>
                    V Olšinách 2300/75
                    <br />
                    108 00, Praha 10
                    <span className="hidden--portrait"> - </span>
                    <br className="hidden--landscape" />
                    Strašnice
                  </p>
                </div>
                <div className="text-col__item text-col__item--base">
                  <h4>Sídlo</h4>
                  <p>
                    Sadová 148
                    <br />
                    250 83, Škvorec
                  </p>
                </div>
                <div className="text-col__item text-col__item--phone">
                  <h4>Mobil</h4>
                  <p>
                    <a href="tel:+420602649362" title="Zavolat">
                      +420 602 649 362
                    </a>
                  </p>
                </div>
                <div className="text-col__item text-col__item--email">
                  <h4>E-mail</h4>
                  <p>
                    <a href="mailto:bemova@bekap.cz" title="Napsat e-mail">
                      bemova@bekap.cz
                    </a>
                    <br />
                    <a href="mailto:bemova.vera@volny.cz" title="Napsat e-mail">
                      bemova.vera@volny.cz
                    </a>
                  </p>
                </div>
                <div className="text-col__item text-col__item--databox">
                  <h4>Datová schránka</h4>
                  <p>estzxri</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
