import React from "react"
import ClockSm from "./../../images/clock--sm.jpg"
import ClockMd from "./../../images/clock--md.jpg"
import ClockLg from "./../../images/clock--lg.jpg"
import Bullet from "./../../images/bullet.svg"

const Taxes = () => {
  return (
    <section className="home__taxes" id="home__taxes">
      <div className="container">
        <div className="content">
          <div className="grid">
            <div className="grid__item tax tax--1">
              <h3 className="heading">
                Daňová evidence
                <br /> příjmů a výdajů
              </h3>
              <p>
                Obsahuje peněžní i nepeněžní příjmy a výdaje
                <br className="hidden--portrait" /> za dané kalendářní období,
                tzn.:
              </p>
              <ul className="list">
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">příjmy obdržené hotově</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">daňově uznatelné náklady</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">odpisy</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">stravné</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">výdaje na auto</span>
                </li>
              </ul>
              <p>
                Není potřeba dělit na druhy nákladů, ani určovat pohyb na
                peněžním účtu. Stačí pouze kolonka zdanitelné příjmy a daňově
                uznatelné náklady.
              </p>
            </div>

            <div className="grid__item tax tax--2">
              <h3 className="heading">
                Daňová evidence
                <br /> majetku a závazků
              </h3>
              <ul className="list">
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">inventura majetku</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">přehled pohledávek</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    přehled závazků, vše
                    <br className="hidden--landscape" /> za dané kalendářní
                    období
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <picture className="img">
        <source media=" (min-width: 3841px)" srcSet={ClockLg} />
        <source media="(min-width: 1921px)" srcSet={ClockMd} />
        <img src={ClockSm} alt="Budík" />
      </picture>
    </section>
  )
}

export default Taxes
