import React from "react"
import Bullet from "./../../images/bullet.svg"
import { Link } from "gatsby"

const Services = () => {
  return (
    <section className="home__services" id="home__services">
      <div className="container">
        <div className="content">
          <div className="grid">
            <div className="grid__item service service--1">
              <h3 className="heading">Podvojné účetnictví</h3>
              <ul className="list">
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    <strong>vedení a zpracování podvojného účetnictví</strong>{" "}
                    pro fyzické i právnické osoby
                  </span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    <strong>zastupování před úřady</strong>
                    <br className="hidden--landscape" /> (FÚ, ČSSZ, zdravotní
                    pojišťovny
                    <br className="hidden--landscape" /> a další)
                  </span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    <strong>zpracování daňových přiznání</strong> k dani z
                    příjmu, DPH, silniční dani, srážkové dani, dani z příjmu ze
                    závislé činnosti a ostatních daní
                  </span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    mzdy, odměny, mzdová a personální agenda,
                    <br className="hidden--portrait" /> odměny statutárních
                    orgánů společností, pracovní cesty
                  </span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    zpracování a odeslání{" "}
                    <strong>kontrolního hlášení a souhrnného hlášení</strong>
                  </span>
                </li>
              </ul>
            </div>

            <div className="grid__item service service--2">
              <h3 className="heading">Další nabízené služby</h3>
              <p>Vypracování účetních předpisů:</p>
              <ul className="list">
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">vnitřní účetní předpisy</span>
                </li>
                <li>
                  <span className="list__bullet">
                    <img src={Bullet} alt="Odrážka" />
                  </span>
                  <span className="list__text">
                    vnitřní mzdové a pracovněprávní předpisy
                  </span>
                </li>
              </ul>
              <p>
                Vnitřní firemní předpisy vycházejí z právního stavu
                <br className="hidden--portrait" /> platného
                <br className="hidden--landscape" /> k 1. 1. 2020.
              </p>
              <Link title="Zobrazit ceník" className="btn" to="/cenik">
                <span className="btn__inner">Zobrazit ceník</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
