import React from "react"
import "../styles/styles.scss"
import SEO from "../components/seo"
import Header from "./../components/header"
import Footer from "./../components/footer"
import Copy from "./../components/copy"
import Hero from "./../components/home/hero"
import Usps from "./../components/home/usps"
import Taxes from "./../components/home/taxes"
import Services from "./../components/home/services"

const IndexPage = () => {
  if (typeof window !== "undefined") {
    require("smooth-scroll")(".smooth-scroll", {
      updateURL: false,
      offset: () => {
        const header = document.getElementById("page__header")
        return header.offsetHeight
      },
    })
  }

  return (
    <div className="page-home">
      <SEO title="BEKAP s.r.o. | Postaráme se o Vaše účetnictví" />
      <Header />
      <Hero />
      <Usps />
      <Taxes />
      <Services />
      <Footer />
      <Copy />
    </div>
  )
}

export default IndexPage
