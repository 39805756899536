import React from "react"
import { Link } from "gatsby"
// import Fade from "react-reveal/Fade"

const Hero = () => {
  return (
    <section className="home__hero" id="home__hero">
      <div className="container">
        <div className="content">
          <h2 className="heading">
            {/* <Fade left duration={2000} delay={100}> */}
            <span className="heading__main">
              Soustřeďte se na to, v čem
              <br className="hidden--landscape" /> jste nejlepší
            </span>
            {/* </Fade> */}
            {/* <Fade left duration={2000} delay={150}> */}
            <span className="heading__sub">My se postaráme o účetnictví</span>
            {/* </Fade> */}
          </h2>

          {/* <Fade left duration={2000} delay={150}> */}
          <p>
            Účetnictví není žádná sranda, když zrovna nejste účetní. Dobrý
            účetní přichází s řešením a nápady, jak vám ušetřit peníze. My si
            vždy víme rady, jsme na drátě i online a rádi vám pokaždé vše
            vysvětlíme
            <br className="hidden--landscape" /> lidskou řečí.
          </p>
          {/* </Fade> */}

          <div className="buttons hidden--portrait">
            {/* <Fade left duration={2000} delay={300}> */}
            <Link
              title="Dozvědět se více"
              className="btn smooth-scroll"
              to="/#home__usps"
            >
              <span className="btn__inner">Dozvědět se více</span>
            </Link>
            {/* </Fade> */}
            {/* <Fade left duration={2000} delay={300}> */}
            <Link title="Zobrazit ceník" className="btn btn--ghost" to="/cenik">
              <span className="btn__inner">Zobrazit ceník</span>
            </Link>
            {/* </Fade> */}
          </div>

          <div className="buttons hidden--landscape">
            {/* <Fade left duration={2000} delay={200}> */}
            <Link
              title="Kontaktovat"
              className="btn smooth-scroll"
              to="/#page__contact"
            >
              <span className="btn__inner">Kontaktovat</span>
            </Link>
            {/* </Fade> */}
            {/* <Fade left duration={2000} delay={300}> */}
            <Link
              title="Dozvědět se více"
              className="btn btn--ghost smooth-scroll"
              to="/#home__usps"
            >
              <span className="btn__inner">Dozvědět se více</span>
            </Link>
            {/* </Fade> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
