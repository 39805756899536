import React from "react"
import CoinsSm from "./../../images/coins--sm.jpg"
import CoinsMd from "./../../images/coins--md.jpg"
import CoinsLg from "./../../images/coins--lg.jpg"
import { Link } from "gatsby"

const Usps = () => {
  return (
    <section className="home__usps" id="home__usps">
      <div className="container">
        <div className="content">
          <div className="grid">
            <div className="grid__item usp usp--1">
              <h3 className="heading">
                <span className="heading__main">
                  Jsme spolehliví
                  <br className="hidden--landscape" /> a pojištění
                </span>
                <span className="heading__sub">Kryjeme vám záda</span>
              </h3>
              <p>
                Naší práci rozumíme a je na nás spoleh. Ale znáte to, i mistr
                tesař
                <br className="hidden--portrait" /> se může někdy utnout. Pokud
                by se něco pokazilo, neplatíte ani korunu.
                <br className="hidden--portrait" /> Jsme pojištění a bereme na
                sebe zodpovědnost za případné chyby.
              </p>
            </div>

            <div className="grid__item usp usp--2">
              <h3 className="heading">
                <span className="heading__main">
                  Jsme váš partner
                  <br className="hidden--landscape" /> a rádce
                </span>
                <span className="heading__sub">Ptejte se úplně na cokoliv</span>
              </h3>
              <p>
                Žádná otázka z účetního vesmíru pro nás není tabu. Na drátě jsme
                pořád, zavolejte nebo napište.
              </p>
            </div>

            <picture className="grid__item img">
              <source media=" (min-width: 3841px)" srcSet={CoinsLg} />
              <source media="(min-width: 1921px)" srcSet={CoinsMd} />
              <img src={CoinsSm} alt="Mince" />
            </picture>

            <div className="grid__item usp usp--3">
              <h3 className="heading">
                <span className="heading__main">
                  Online komunikace,
                  <br /> moderní přístup
                </span>
                <span className="heading__sub">Jsme online ve dne v noci</span>
              </h3>
              <p>
                Zapomeňte na hromady zbytečných papírů, pracujeme online
                <br className="hidden--portrait" /> a efektivně, moc dobře víme,
                jak drahý je váš čas.
              </p>
            </div>

            <Link
              title="Kontaktovat"
              className="btn smooth-scroll"
              to="/#page__contact"
            >
              <span className="btn__inner">Kontaktovat</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Usps
